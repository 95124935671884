import React from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import * as Sentry from '@sentry/node';

import ErrorMessaging from 'src/components/global/messaging/ErrorMessaging';

const Custom404 = () => {
  const router = useRouter();
  // log 404 in Sentry, include requested path
  // see _error.jsx for server side errors
  Sentry.captureException(new Error(`404 Not Found: ${router.asPath}`));

  return (
    <>
      <NextSeo nofollow title="Page Not Found" />
      <ErrorMessaging error={404} />
    </>
  );
};

export default Custom404;
